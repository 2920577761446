.Tag-div {
	margin: 0 auto;
	padding: 12px;
	max-width: 620px;
}
.Tag-edit-button,
.Tag-save-button,
.Tag-cancel-button,
.Tag-button-div {
	float: right;
	margin-top: 5px;
}
.Tag-name {
	text-align: center;
	padding: 20px 4px;
}
